import React, { createContext, useContext, useEffect, useState } from 'react'
import { LoginType, Login } from '../api_functions/Login'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Examinee } from '../api_functions/Examinee'

interface AuthContextProps {
  user: User | null
  updateUser: (userData: User) => void
  // eslint-disable-next-line no-unused-vars
  login: (loginData: LoginType) => void
  logout: () => void
}

const AuthContext = createContext<AuthContextProps | null>(null)

interface AuthProviderProps {
  children: React.ReactNode
}

interface User {
  organizations_id: string
  permission: number //0:master, 1:admin, 2:user
  examinee?: Examinee
  token: string | null
}

const initUser = {
  organizations_id: '',
  permission: -1,
  token: null,
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'user'])

  useEffect(() => {
    const user = cookies.user
    token = cookies.token

    if (user) {
      setUser(user)
    } else {
      if (!location.pathname.includes('loginadmin') && !location.pathname.includes('loginmaster')) {
        setUser(initUser)
        navigate('/loginsurvey')
      }
    }
  }, [])

  const login = async (loginData: LoginType) => {
    try {
      const res = await Login(loginData)
      if (res.authentication !== 'True') throw new Error('Authentication failed')

      let navigatePath = ''
      let userPermission

      switch (loginData.name) {
        case 'master':
          navigatePath = '/statistics'
          userPermission = 0
          break

        case 'admin':
          navigatePath = '/adminstatistics'
          userPermission = 1
          break

        case 'user':
          navigatePath = '/survey'
          userPermission = 2
          break

        default:
          window.alert('不明なエラー。お問い合わせください。')
          return
      }

      const userData = {
        organizations_id: loginData.organizations_id,
        permission: userPermission,
        token: res.token,
      }

      token = res.token

      setUser(userData)
      setCookie('user', JSON.stringify(userData), { path: '/', secure: true })
      setCookie('token', token ?? '', { path: '/', secure: true })
      navigate(navigatePath)
    } catch (error) {
      window.alert('ログインに失敗しました。もう一度入力してください。')
    }
  }

  const logout = () => {
    setUser(initUser)
    removeCookie('token')
    removeCookie('user')
    navigate('/loginMaster')
  }

  const updateUser = (userData:User) => {
    setUser(userData)
    setCookie('user', JSON.stringify(userData), { path: '/', secure: true })
  }

  return <AuthContext.Provider value={{ user, updateUser, login, logout }}>{children}</AuthContext.Provider>
}

let token: string | null = null
export const getToken = (): string | null => {
  return token
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error('useAuth must be used within an AuthProvider.')
  return context
}
