import { getToken } from '../context/AuthContext'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const subscriptionKey = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY

export const buildUrl = (endpoint: string) => {
  return `${BASE_URL}/${endpoint}`
}

export const getBearerToken = (): string => {
  const token = getToken()
  // console.log('token', token)
  return `Bearer ${token ?? ''}`
}
