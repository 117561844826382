import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const LoginSurvey = React.lazy(() => import('./views/loginsurvey/LoginSurvey'))
const LoginAdmin = React.lazy(() => import('./views/loginadmin/LoginAdmin'))
const LoginMaster = React.lazy(() => import('./views/loginmaster/LoginMaster'))
const Page404 = React.lazy(() => import('./views/page404/Page404'))

class App extends Component {
  render(): JSX.Element {
    return (
      <HashRouter>
        <AuthProvider>
          <Suspense fallback={<CSpinner color='primary' />}>
            <Routes>
              <Route path='/loginsurvey' element={<LoginSurvey />} />
              <Route path='/loginadmin' element={<LoginAdmin />} />
              <Route path='/loginmaster' element={<LoginMaster />} />
              <Route path='/404' element={<Page404 />} />
              <Route path='*' element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </HashRouter>
    )
  }
}

export default App
