import { buildUrl, subscriptionKey } from './Util'

const LOGIN = buildUrl('login')

export type LoginType = {
  organizations_id: string
  name: string
  pw: string
}

export const Login = async (login: LoginType) => {
  if (!subscriptionKey) throw new Error('subscriptionKey is not defined')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey },
    body: JSON.stringify(login),
  }
  try {
    const response = await fetch(LOGIN, requestOptions)
    if (!response.ok) return null
    const data = await response.json()

    return data
  } catch (error) {
    console.log('An error occurred:', error)
  }
}
